import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import classes from "./QS1.module.css"
import clsx from "clsx"

import QuestionnaireButton from "../../atoms/QuestionnaireButton"
import QuestionHeading from "../QuestionHeading"
import QuestionLabel from "../QuestionLabel"
import Countdown from "../Countdown"
import PriceTag from "../PriceTag/PriceTag"

export const price = ({ values, total }) => {
  let nextPrice = total

  if (values.deliveryTime === "4days") {
    nextPrice += 199
  }

  return nextPrice
}

export const stepPrice = ({ total, values }) => {
  let previousTotal = total

  if (values.deliveryTime === "4days") {
    previousTotal -= 199
  }

  return previousTotal
}

export const getPreviousStepId = ({ values }) => {
  const category =
    typeof window !== "undefined" && localStorage.getItem("category")

  if (category === "Events Wedding") {
    return "finalEditedVideo"
  } else if (category === "Time lapse") {
    return "timeLapse"
  } else if (category === "Trimming Scrubbing") {
    return "trimming"
  } else if (category === "MultiCam") {
    return "cameras"
  } else {
    return "moreThan1FinalVideo"
  }
}

export const getNextStepId = ({ values }) => {
  return "email"
}

const initialValues = {
  deliveryTime: "",
}

const validate = ({ deliveryTime }) => {
  const errors = {}

  if (!deliveryTime) {
    errors.category = true
  }

  return errors
}

const formikConfig = {
  validate,
  initialValues,
  isInitialValid: false,
}

function DeliveryTime(props) {
  const { handleDone, handleBack, total, handleDiscount } = props

  const { values, handleChange, setFieldValue, isValid } = useFormik(
    formikConfig
  )

  const [progress, setProgress] = useState(25)

  useEffect(
    function () {
      let newProgress = 34

      if (values.deliveryTime) {
        newProgress += 66
      }

      if (progress !== newProgress) {
        setProgress(newProgress)
      }
    },
    [values]
  )

  if (typeof window !== "undefined")
    window.addEventListener(
      "resize",
      function () {
        window.scrollTo(0, document.body.scrollHeight)
        document.body.scrollTop = 0
      },
      { passive: true }
    )

  function handleSubmit(e) {
    e.preventDefault()
    handleDone(values)
  }

  return (
    <>
      <Countdown count={1} value={progress} />
      <form onSubmit={handleSubmit}>
        <div className={classes.section}>
          <QuestionHeading>AND FOR THE FINAL STEP, DELIVERY!</QuestionHeading>

          <QuestionLabel>
            How fast would you like to receive the project?
          </QuestionLabel>

          <div className="flex justify-center mt-4 md:mt-16">
            <QuestionnaireButton
              className={classes.yesNoBtns}
              type="button"
              variant={values.deliveryTime === "7-10days" && "filled"}
              onClick={() => setFieldValue("deliveryTime", "7-10days")}
            >
              7 - 10 days
              <span class="block">is fine with me</span>
            </QuestionnaireButton>

            <QuestionnaireButton
              className={clsx(classes.yesNoBtns, "ml-4 md:ml-32")}
              type="button"
              variant={values.deliveryTime === "4days" && "filled"}
              onClick={() => setFieldValue("deliveryTime", "4days")}
            >
              4 days
              <span class="block">+ 199$</span>
            </QuestionnaireButton>
          </div>
        </div>

        <div
          className={classes.backBtn}
          onClick={e => handleBack(values)}
          style={{ outline: "none" }}
        >
          BACK
        </div>

        {progress === 100 && (
          <button
            type="submit"
            className={classes.nextBtn}
            style={{ outline: "none" }}
          >
            NEXT
          </button>
        )}

        <div className={classes.price}>
          <PriceTag price={price({ total, values })} />
        </div>
      </form>
    </>
  )
}

export default DeliveryTime
