import React from "react"
import Tag from "../../assets/svg/price_tag_extra.svg"
import "./ExtraPriceTag.css"

function ExtraPriceTag({ price }) {
  return (
    <div className="extraprice-tag-container">
      <Tag /> <div className="extraprice-tag-value">+{price}$</div>
    </div>
  )
}

export default ExtraPriceTag
