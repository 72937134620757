import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import clsx from "clsx"

import QuestionnaireButton from "../../atoms/QuestionnaireButton"
import classes from "./QS1.module.css"
import QuestionLabel from "../QuestionLabel"
import Countdown from "../Countdown"
import PriceTag from "../PriceTag/PriceTag"
import { useMediaQuery } from "react-responsive"

const amountOfPhotos = {
  upTo50: { label: "up to 50", price: 30 },
  from50To100: { label: "50-100", price: 59 },
  from101To200: { label: "101-200", price: 109 },
  from201To300: { label: "201-300", price: 159 },
  from301To400: { label: "301-400", price: 209 },
  from401To500: { label: "401-500", price: 259 },
}

export const price = ({ total, values }) => {
  let nextTotal = total

  if (values.includePhotos === "yes" && values.numberOfPhotos) {
    nextTotal += amountOfPhotos[values.numberOfPhotos].price
  }

  return nextTotal
}

export const stepPrice = ({ total, values }) => {
  let previousTotal = total

  if (values.includePhotos === "yes" && values.numberOfPhotos) {
    previousTotal -= amountOfPhotos[values.numberOfPhotos].price
  }

  return previousTotal
}

export const getPreviousStepId = ({ values }) => {
  return "lengthOfRawFootage"
}

export const getNextStepId = ({ values }) => {
  return "musicPreference"
}

const validate = values => {
  const errors = {}

  return errors
}

function IncludePhotos(props) {
  const {
    handleDone,
    total,
    handleBack,
    data,
    setState,
    setMakeRotate,
    makeRotate,
  } = props
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })

  const initialValues = data
    ? data
    : {
        includePhotos: null,
        numberOfPhotos: null,
      }

  const formikConfig = {
    validate,
    initialValues,
    isInitialValid: false,
  }

  const { values, handleChange, setFieldValue, isValid } = useFormik(
    formikConfig
  )

  const [progress, setProgress] = useState(25)

  useEffect(
    function () {
      let newProgress = 20

      if (values.includePhotos === "yes") {
        newProgress += 40

        if (values.numberOfPhotos) {
          newProgress += 40
        }
      }

      if (values.includePhotos === "no") {
        newProgress += 80
      }

      if (progress !== newProgress) {
        setProgress(newProgress)
      }
    },
    [values]
  )

  if (typeof window !== "undefined")
    window.addEventListener(
      "resize",
      function () {
        window.scrollTo(0, document.body.scrollHeight)
        document.body.scrollTop = 0
      },
      { passive: true }
    )

  function handleSubmit(e) {
    e.preventDefault()
    handleDone(values)
  }

  return (
    <>
      <Countdown count={2} value={progress} />
      <form onSubmit={handleSubmit}>
        <div className={classes.section}>
          <div className="flex flex-col mx-auto md:mx-0 md:flex-row justify-start ">
            <QuestionLabel>Would you like to include photos?</QuestionLabel>

            <div className="flex md:ml-4 justify-center">
              <QuestionnaireButton
                type="button"
                className={clsx("mr-6", classes.yesNoBtns)}
                onClick={() => {
                  if (!isMobile) {
                    setMakeRotate(!makeRotate)
                  }
                  setFieldValue("includePhotos", "yes")
                }}
                variant={values.includePhotos === "yes" && "filled"}
              >
                Yes
              </QuestionnaireButton>

              <QuestionnaireButton
                className={classes.yesNoBtns}
                type="button"
                onClick={() => {
                  if (!isMobile) {
                    setMakeRotate(!makeRotate)
                  }
                  setFieldValue("includePhotos", "no")
                }}
                variant={values.includePhotos === "no" && "filled"}
              >
                No
              </QuestionnaireButton>
            </div>
          </div>

          {values.includePhotos === "yes" && (
            <div>
              <QuestionLabel>How many would you like to add?</QuestionLabel>

              <div className={classes.buttonGrid3}>
                {Object.entries(amountOfPhotos).map(([key, value]) => (
                  <QuestionnaireButton
                    className={classes.gridButtons}
                    key={key}
                    type="button"
                    variant={values.numberOfPhotos === key && "filled"}
                    name={key}
                    onClick={e =>
                      setFieldValue("numberOfPhotos", e.currentTarget.name)
                    }
                  >
                    {value.label}
                    <span className="block text-sm">+${value.price}</span>
                  </QuestionnaireButton>
                ))}
              </div>
            </div>
          )}
        </div>

        <div
          className={classes.backBtn}
          onClick={e => handleBack(values)}
          style={{ outline: "none" }}
        >
          BACK
        </div>

        {progress === 100 && (
          <button
            type="submit"
            className={classes.nextBtn}
            style={{ outline: "none" }}
          >
            NEXT
          </button>
        )}

        <div className={classes.price}>
          <PriceTag price={price({ total, values })} />
        </div>
      </form>
    </>
  )
}

export default IncludePhotos
