import React, { useState, useEffect, useRef } from "react"
import { useFormik } from "formik"

import QuestionnaireButton from "../../atoms/QuestionnaireButton"
import classes from "./QS1.module.css"
import QuestionHeading from "../QuestionHeading"
import QuestionLabel from "../QuestionLabel"
import Countdown from "../Countdown"
import clsx from "clsx"

const categories = {
  wedding: "Events Wedding",
  holiday: "Holiday Family",
  corporate: "Corporate Commercial",
  food: "Food Services",
  realestate: "Real estates Resorts",
  sports: "Sports Adrenaline",
  timelapse: "Time lapse",
  trim: "Trimming Scrubbing",
  multicam: "MultiCam",
  gopro: "Drone GoPro",
  youtube: "Youtube Instagram",
  other: "Other",
}

export const price = () => 0
export const stepPrice = () => 0

export const getNextStepId = ({ values }) => {
  const { category } = values

  localStorage && localStorage.setItem("category", category)

  if (category === "MultiCam") return "lengthOfRawFootage"
  else return "importantScenes"
}

const validate = ({ name, category }) => {
  const errors = {}

  // if (!name) {
  //   errors.name = true
  // }

  if (!category) {
    errors.category = true
  }

  return errors
}

const Follow3QuickSteps = props => {
  const { handleDone, data, setState } = props
  const [progress, setProgress] = useState(25)

  const initialValues = data
    ? data
    : {
        name: "",
        category: "",
      }

  const formikConfig = {
    validate,
    initialValues,
    isInitialValid: false,
  }

  const { values, handleChange, setFieldValue, isValid } = useFormik(
    formikConfig
  )

  function handleSubmit(e) {
    e.preventDefault()
    handleDone(values)
  }

  function handleButtonSelect(e) {
    setFieldValue("category", e.target.name)
  }

  useEffect(
    function () {
      let newProgress = 25

      // if (values.name) {
      //   newProgress += 25
      // }

      if (values.category) {
        newProgress += 75
      }

      if (progress !== newProgress) {
        setProgress(newProgress)
      }
    },
    [values]
  )

  return (
    <>
      <Countdown count={3} value={progress} />
      <form onSubmit={handleSubmit}>
        <div className={classes.section}>
          <QuestionHeading>
            Hello, &nbsp;
            <input
              type="text"
              name="name"
              value={values.name}
              style={{
                background: "none",
                paddingLeft: "1rem",
                marginRight: "3px",
                outline: "2px solid #DA138A",
              }}
              placeholder="type name here"
              onChange={handleChange}
            />
            !
          </QuestionHeading>

          <QuestionHeading className="mt-2 md:mt-0">
            PLEASE FOLLOW 3 QUICK STEPS TO CREATE YOUR OWN CUSTOMIZED PRICE!
          </QuestionHeading>

          <QuestionLabel>Select your type of video</QuestionLabel>

          <div className={classes.buttonGrid}>
            {Object.values(categories).map(option => (
              <QuestionnaireButton
                className={clsx(
                  classes.gridButtons,
                  classes.paddingGridButtons
                )}
                style={{
                  lineHeight: "22px",
                }}
                key={option}
                type="button"
                variant={values.category === option && "filled"}
                name={option}
                onClick={e => {
                  handleButtonSelect(e)
                }}
              >
                {option}
              </QuestionnaireButton>
            ))}
          </div>
        </div>

        <div className={classes.bottom}>
          {progress === 100 && (
            <button
              type="submit"
              className={classes.nextBtn}
              style={{ outline: "none" }}
            >
              NEXT
            </button>
          )}
        </div>
      </form>
    </>
  )
}

export default Follow3QuickSteps
