import React from 'react'
import clsx from 'clsx'

import classes from './QuestionnaireButton.module.css'

function Button ({ variant, className,...rest }) {
  const rootClassName = clsx(
    className,
    classes.root,
    classes[variant]
  )

  return (
    <button
      className={rootClassName}
      {...rest}
    />
  )
}

export default Button
