import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import classes from "./QS1.module.css"
import QuestionHeading from "../QuestionHeading"
import QuestionLabel from "../QuestionLabel"
import Countdown from "../Countdown"
import PriceTag from "../PriceTag/PriceTag"

const discounts = [
  {
    label: "Get 5% discount if you follow us and give us credit on IG",
    value: 0.05,
  },
  {
    label: "Get 5% discount if you let us use our credentials on your video",
    value: 0.05,
  },
  {
    label: "No, thank you for the offer",
    value: 0,
  },
]

export const price = ({ values, total }) => {
  let nextPrice = total

  if (values.discount) nextPrice = nextPrice - nextPrice * values.discount

  return nextPrice
}

export const getNextStepId = ({ values }) => {
  return "done"
}

const initialValues = {
  discount: [],
}

const validate = () => {
  const errors = {}

  return errors
}

const formikConfig = {
  validate,
  initialValues,
  isInitialValid: false,
}

const Discount = props => {
  const { handleDone, total } = props

  const { values, handleChange, setFieldValue, isValid } = useFormik(
    formikConfig
  )

  const [progress, setProgress] = useState(25)

  useEffect(
    function () {
      let newProgress = 50

      if (values.discount >= 0) {
        newProgress += 50
      }

      if (progress !== newProgress) {
        setProgress(newProgress)
      }
    },
    [values]
  )

  function handleSubmit(e) {
    e.preventDefault()
    handleDone(values)
  }

  return (
    <>
      <Countdown count={1} value={progress} />

      <form onSubmit={handleSubmit}>
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <QuestionHeading>
            SHARING IS CARING AND YOU ALSO GET A DISCOUNT!
          </QuestionHeading>
          <QuestionLabel>Help our community grow?</QuestionLabel>

          {discounts.map(discount => {
            return (
              <div className="mt-8">
                <div className={classes.container}>
                  <input
                    type="radio"
                    name="discount"
                    className="discount"
                    value={discount.value}
                    onClick={e => {
                      setFieldValue("discount", discount.value)
                    }}
                  />
                  <span className={classes.checkmark}></span>
                </div>

                <span className="ml-8 font-primary font-semibold">
                  {discount.label}
                </span>
              </div>
            )
          })}
        </div>

        {progress === 100 && (
          <button
            type="submit"
            className={classes.startProjectBtn}
            style={{ outline: "none" }}
          >
            START <br /> PROJECT
          </button>
        )}

        <div className={classes.price}>
          <PriceTag price={price({ total, values })} />
        </div>
      </form>
    </>
  )
}

export default Discount
