import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import clsx from "clsx"
import QuestionnaireButton from "../../atoms/QuestionnaireButton"

import classes from "./QS1.module.css"
import QuestionLabel from "../QuestionLabel"
import Countdown from "../Countdown"
import PriceTag from "../PriceTag/PriceTag"

const footageLengthCategories = {
  upTo5: "Up to 5 hours",
  from5to10: "Up to 10 hours",
}

const priceByFootageLengthCategory = {
  upTo5: 99,
  from5to10: 149,
}

const photosChoice = {
  upTo300: "Up to 300",
  moreThan300: "More than 300",
}

const priceByPhotosChoice = {
  upTo300: 59,
  moreThan300: 109,
}

const choicesArray = [footageLengthCategories, photosChoice]

export const price = ({ total, values }) => {
  let nextTotal = total

  if (
    values.footageLengthCategory &&
    priceByFootageLengthCategory[values.footageLengthCategory]
  ) {
    nextTotal += priceByFootageLengthCategory[values.footageLengthCategory]
  }

  if (values.photosChoice && priceByPhotosChoice[values.photosChoice]) {
    nextTotal += priceByPhotosChoice[values.photosChoice]
  }

  return nextTotal
}

export const stepPrice = ({ total, values }) => {
  let previousTotal = total

  if (
    values.footageLengthCategory &&
    priceByFootageLengthCategory[values.footageLengthCategory]
  ) {
    previousTotal -= priceByFootageLengthCategory[values.footageLengthCategory]
  }

  if (values.photosChoice && priceByPhotosChoice[values.photosChoice]) {
    previousTotal -= priceByPhotosChoice[values.photosChoice]
  }

  return previousTotal
}

export const getPreviousStepId = ({ values }) => {
  return "importantScenes"
}

export const getNextStepId = ({ values }) => {
  return "deliveryTime"
}

const validate = values => {
  const errors = {}

  return errors
}

const TimeLapse = props => {
  const { handleDone, handleBack, total, data } = props

  const initialValues = data
    ? data
    : {
        footageLengthCategory: "",
        photosChoice: "",
        lengthOfFinalVideo: "",
      }

  const formikConfig = {
    validate,
    initialValues,
    isInitialValid: false,
  }

  const { values, handleChange, setFieldValue, isValid } = useFormik(
    formikConfig
  )

  const [progress, setProgress] = useState(25)

  useEffect(
    function () {
      let newProgress = 20

      if (values.footageLengthCategory) {
        newProgress += 40
      }

      if (values.photosChoice) {
        newProgress += 40
      }

      if (values.lengthOfFinalVideo) {
        newProgress += 40
      }

      if (progress !== newProgress) {
        setProgress(newProgress)
      }
    },
    [values]
  )

  function handleSubmit(e) {
    e.preventDefault()
    handleDone(values)
  }

  return (
    <>
      <Countdown count={2} value={progress} />
      <form onSubmit={handleSubmit}>
        <div className={classes.section}>
          <QuestionLabel>
            What is the length of your footage (hours)?
          </QuestionLabel>
          <div className={classes.buttonGrid}>
            {Object.entries(footageLengthCategories).map(([key, value]) => (
              <QuestionnaireButton
                className={classes.gridButtons}
                key={key}
                type="button"
                variant={values.footageLengthCategory === key && "filled"}
                name={key}
                onClick={e => {
                  setFieldValue("footageLengthCategory", e.currentTarget.name)
                  setFieldValue("photosChoice", "")
                }}
              >
                {value}
                {priceByFootageLengthCategory[key] && (
                  <span className="block text-sm">
                    +${priceByFootageLengthCategory[key]}
                  </span>
                )}
              </QuestionnaireButton>
            ))}
          </div>

          <QuestionLabel className="mt-8 md:mt-32">
            How many photos do you have?
          </QuestionLabel>
          <div className={classes.buttonGrid}>
            {Object.entries(photosChoice).map(([key, value]) => (
              <QuestionnaireButton
                className={classes.gridButtons}
                key={key}
                type="button"
                variant={values.photosChoice === key && "filled"}
                name={key}
                onClick={e => {
                  setFieldValue("photosChoice", e.currentTarget.name)
                  setFieldValue("footageLengthCategory", "")
                }}
              >
                {value}
                {priceByPhotosChoice[key] && (
                  <span className="block text-sm">
                    +${priceByPhotosChoice[key]}
                  </span>
                )}
              </QuestionnaireButton>
            ))}
          </div>

          {(values.footageLengthCategory !== "" ||
            values.photosChoice !== "") && (
            <>
              <QuestionLabel className="mt-8 md:mt-32">
                Please specify the desired length of the time lapse
              </QuestionLabel>

              <input
                className={clsx(
                  classes.lengthOfTimeLapseInput,
                  "p-4 w-full border border-solid border-black"
                )}
                type="text"
                id="custom"
                placeholder="Type a value here (minutes)"
                value={values.lengthOfFinalVideo}
                name="lengthOfFinalVideo"
                onChange={handleChange}
              />
            </>
          )}
        </div>

        <div
          className={classes.backBtn}
          onClick={e => handleBack(values)}
          style={{ outline: "none" }}
        >
          BACK
        </div>

        {progress === 100 && (
          <button
            type="submit"
            className={classes.nextBtn}
            style={{ outline: "none" }}
          >
            NEXT
          </button>
        )}

        <div className={classes.price}>
          <PriceTag price={price({ total, values })} />
        </div>
      </form>
    </>
  )
}

export default TimeLapse
