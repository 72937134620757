import React, { useState, useEffect } from "react"

import { useFormik } from "formik"
import clsx from "clsx"

import QuestionnaireButton from "../../atoms/QuestionnaireButton"

import classes from "./QS1.module.css"
import QuestionLabel from "../QuestionLabel"
import Countdown from "../Countdown"
import PriceTag from "../PriceTag/PriceTag"

const footageLengthCategories = {
  upTo4: "4 Hours",
  upTo8: "8 Hours",
  upTo12: "12 Hours",
}

const priceByFootageLengthCategory = {
  upTo4: 119,
  upTo8: 189,
  upTo12: 259,
}

export const price = ({ total, values }) => {
  let nextTotal = total

  if (
    values.footageLengthCategory &&
    priceByFootageLengthCategory[values.footageLengthCategory]
  ) {
    nextTotal += priceByFootageLengthCategory[values.footageLengthCategory]
  }

  return nextTotal
}

export const stepPrice = ({ total, values }) => {
  let previousTotal = total

  if (
    values.footageLengthCategory &&
    priceByFootageLengthCategory[values.footageLengthCategory]
  ) {
    previousTotal -= priceByFootageLengthCategory[values.footageLengthCategory]
  }
  return previousTotal
}

export const getPreviousStepId = ({ values }) => {
  return "importantScenes"
}

export const getNextStepId = ({ values }) => {
  return "deliveryTime"
}

const validate = values => {
  const errors = {}

  return errors
}

const Trimming = props => {
  const { handleDone, total, handleBack, data } = props

  const initialValues = data
    ? data
    : {
        footageLengthCategory: "",
        lengthOfFinalVideo: "",
      }

  const formikConfig = {
    validate,
    initialValues,
    isInitialValid: false,
  }

  const { values, handleChange, setFieldValue, isValid } = useFormik(
    formikConfig
  )

  function handleButtonSelect(e) {
    setFieldValue("footageLengthCategory", e.currentTarget.name)
  }

  const [progress, setProgress] = useState(25)

  useEffect(
    function () {
      let newProgress = 20

      if (values.footageLengthCategory) {
        newProgress += 40
      }

      if (values.lengthOfFinalVideo) {
        newProgress += 40
      }

      if (progress !== newProgress) {
        setProgress(newProgress)
      }
    },
    [values]
  )

  function handleSubmit(e) {
    e.preventDefault()
    handleDone(values)
  }

  return (
    <>
      <Countdown count={2} value={progress} />
      <form onSubmit={handleSubmit}>
        <div className={classes.section}>
          {" "}
          <QuestionLabel>
            What is the length of your footage (hours)?
          </QuestionLabel>
          <div className={classes.buttonGrid3}>
            {Object.entries(footageLengthCategories).map(([key, value]) => (
              <QuestionnaireButton
                className={classes.gridButtons}
                key={key}
                type="button"
                variant={values.footageLengthCategory === key && "filled"}
                name={key}
                onClick={handleButtonSelect}
              >
                {value}
                {priceByFootageLengthCategory[key] && (
                  <span className="block text-sm">
                    +${priceByFootageLengthCategory[key]}
                  </span>
                )}
              </QuestionnaireButton>
            ))}
          </div>
          {values.footageLengthCategory !== "" && (
            <>
              <QuestionLabel className="mt-8 md:mt-64">
                Please specify the desired length of the final trimmed footage
              </QuestionLabel>

              <input
                className={clsx(
                  classes.lengthOfTimeLapseInput,
                  "p-4 w-full border border-solid border-black"
                )}
                type="text"
                id="custom"
                placeholder="Type a value here (minutes)"
                value={values.lengthOfFinalVideo}
                name="lengthOfFinalVideo"
                onChange={handleChange}
              />
            </>
          )}
        </div>

        <div
          className={classes.backBtn}
          onClick={e => handleBack(values)}
          style={{ outline: "none" }}
        >
          BACK
        </div>
        {progress === 100 && (
          <button
            type="submit"
            className={classes.nextBtn}
            style={{ outline: "none" }}
          >
            NEXT
          </button>
        )}

        <div className={classes.price}>
          <PriceTag price={price({ total, values })} />
        </div>
      </form>
    </>
  )
}

export default Trimming
