import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import QuestionnaireButton from "../../atoms/QuestionnaireButton"
import classes from "./QS1.module.css"
import QuestionLabel from "../QuestionLabel"
import Countdown from "../Countdown"
import PriceTag from "../PriceTag/PriceTag"
import ExtraPriceTag from "../ExtraPriceTag/ExtraPriceTag"

function arrayRemove(array, value) {
  const index = array.indexOf(value)

  return [...array.slice(0, index), ...array.slice(index + 1)]
}

const priceByFinalEditedVideoForm = {
  highlightFrom3To5mins: 199,
  highlightFrom5To10mins: 279,
  teaserUpTo1Min: 149,
  fullLengthFrom30To60: 169,
  fullLengthFrom60To120: 239,
  ceremony: 129,
}

export const price = ({ total, values }) => {
  let nextTotal = total

  // if (values.finalEditedVideo && values.finalEditedVideo.length > 1)
  values.finalEditedVideo.forEach((finalEditedVideoForm, i) => {
    nextTotal += priceByFinalEditedVideoForm[finalEditedVideoForm]
  })

  return nextTotal
}

export const stepPrice = ({ total, values }) => {
  let previousTotal = total

  // if (values.finalEditedVideo && values.finalEditedVideo.length > 1)
  values.finalEditedVideo.forEach((finalEditedVideoForm, i) => {
    previousTotal -= priceByFinalEditedVideoForm[finalEditedVideoForm]
  })

  return previousTotal
}

export const getPreviousStepId = ({ values }) => {
  return "musicPreference"
}

export const getNextStepId = ({ values }) => {
  return "deliveryTime"
}

const initialValues = {
  finalEditedVideo: [],
}

const validate = values => {
  const errors = {}

  return errors
}

const formikConfig = {
  validate,
  initialValues,
  isInitialValid: false,
}

function FinalEditedVideo(props) {
  const { handleDone, total, handleDiscount, handleBack } = props
  const { values, handleChange, setFieldValue, isValid } = useFormik(
    formikConfig
  )

  const [progress, setProgress] = useState(25)

  useEffect(
    function () {
      let newProgress = 20

      if (values.finalEditedVideo.length > 0) {
        newProgress += 80
      }

      if (progress !== newProgress) {
        setProgress(newProgress)
      }
    },
    [values]
  )

  function handleSubmit(e) {
    e.preventDefault()
    handleDone(values)
  }
  return (
    <>
      <Countdown count={1} value={progress} />

      <form onSubmit={handleSubmit}>
        <div className={classes.section}>
          <>
            <QuestionLabel>
              Please choose final edited video. More than one? Choose as many as
              you want!
            </QuestionLabel>

            <div className={classes.buttonGrid3}>
              {[
                {
                  title: "Highlight",
                  label: "3-5 mins",
                  value: "highlightFrom3To5mins",
                },
                {
                  title: "Highlight",
                  label: "5-10 mins",
                  value: "highlightFrom5To10mins",
                },
                {
                  title: "Teaser",
                  label: "up to 1 min",
                  value: "teaserUpTo1Min",
                },
                {
                  title: "Full length",
                  label: "movie 30-60",
                  value: "fullLengthFrom30To60",
                },
                {
                  title: "Full length",
                  label: "movie 60- 120",
                  value: "fullLengthFrom60To120",
                },
                { title: "Ceremony", label: "Video", value: "ceremony" },
              ].map(finalEditedVideo => (
                <div style={{ position: "relative", textAlign: "center" }}>
                  <QuestionnaireButton
                    className={classes.gridButtons}
                    key={finalEditedVideo.value}
                    type="button"
                    variant={
                      values.finalEditedVideo.includes(
                        finalEditedVideo.value
                      ) && "filled"
                    }
                    onClick={() => {
                      if (
                        values.finalEditedVideo.includes(finalEditedVideo.value)
                      ) {
                        setFieldValue(
                          "finalEditedVideo",
                          arrayRemove(
                            values.finalEditedVideo,
                            finalEditedVideo.value
                          )
                        )
                      } else {
                        setFieldValue("finalEditedVideo", [
                          ...values.finalEditedVideo,
                          finalEditedVideo.value,
                        ])
                      }
                    }}
                  >
                    <span class="block">{finalEditedVideo.title}</span>
                    <span class="block">{finalEditedVideo.label}</span>
                  </QuestionnaireButton>

                  {/* {values.finalEditedVideo.length > 0 &&
                    finalEditedVideo.value !== values.finalEditedVideo[0] && ( */}
                  <div className={classes.extraPrice}>
                    <ExtraPriceTag
                      price={
                        priceByFinalEditedVideoForm[finalEditedVideo.value]
                      }
                    />
                  </div>
                  {/* )} */}
                </div>
              ))}
            </div>
          </>
        </div>

        <div
          className={classes.backBtn}
          onClick={e => handleBack(values)}
          style={{ outline: "none" }}
        >
          BACK
        </div>

        {values.finalEditedVideo.length > 0 && (
          <button
            type="submit"
            className={classes.nextBtn}
            style={{ outline: "none" }}
          >
            NEXT
          </button>
        )}

        <div className={classes.price}>
          <PriceTag price={price({ total, values })} />
        </div>
      </form>
    </>
  )
}

export default FinalEditedVideo
