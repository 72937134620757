import React, { useEffect, useState } from "react"
import Questionnaire from "../components/Questionnaire"
import WhiteLogoImage from "../gatsbyImages/WhiteLogoImage"
import classes from "./questionnaire.module.css"
import Dropdown from "../components/Dropdown"
import Layout from "../components/layout"

const QuestionnairePage = () => {
  const [isDropdownOpen, setisDropdownOpen] = useState(false)
  const width = typeof window !== "undefined" && window.screen.width
  const [isMobile, setisMobile] = useState()

  useEffect(() => {
    if (width <= 768) setisMobile(true)
    else setisMobile(false)
  })

  return (
    <Layout>
      <div className="relative">
        {!isDropdownOpen && (
          <div
            className={classes.logo}
            onClick={e => setisDropdownOpen(!isDropdownOpen)}
          >
            {isMobile && <WhiteLogoImage />}
          </div>
        )}

        {isDropdownOpen && <Dropdown white={true} />}
        <div onClick={e => setisDropdownOpen(false)}>
          <Questionnaire />
        </div>
      </div>
    </Layout>
  )
}

export default QuestionnairePage
