import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useFormik } from "formik"
import clsx from "clsx"

import QuestionnaireButton from "../../atoms/QuestionnaireButton"
import classes from "./QS1.module.css"
import QuestionLabel from "../QuestionLabel"
import Countdown from "../Countdown"
import PriceTag from "../PriceTag/PriceTag"
import { useMediaQuery } from "react-responsive"

const numberOfCams = {
  cams3: { label: "3 cameras", price: 119 },
  cams4: { label: "4 cameras", price: 159 },
  cams5: { label: "5 cameras", price: 229 },
}

export const price = ({ total, values }) => {
  let nextTotal = total

  if (values.numberOfCams) {
    nextTotal += numberOfCams[values.numberOfCams].price
  }

  return nextTotal
}

export const stepPrice = ({ total, values }) => {
  let previousTotal = total

  if (values.numberOfCams) {
    previousTotal -= numberOfCams[values.numberOfCams].price
  }
  return previousTotal
}

export const getPreviousStepId = ({ values }) => {
  return "lengthOfRawFootage"
}

export const getNextStepId = ({ values }) => {
  return "deliveryTime"
}

const validate = values => {
  const errors = {}

  return errors
}

function Cameras(props) {
  const { handleDone, total, handleBack, data, setState } = props
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })

  const initialValues = data
    ? data
    : {
        numberOfCams: "",
      }

  const formikConfig = {
    validate,
    initialValues,
    isInitialValid: false,
  }

  const { values, handleChange, setFieldValue, isValid } = useFormik(
    formikConfig
  )

  const [progress, setProgress] = useState(25)

  useEffect(
    function () {
      let newProgress = 20

      if (values.numberOfCams) {
        newProgress += 80
      }

      if (progress !== newProgress) {
        setProgress(newProgress)
      }
    },
    [values]
  )

  function handleSubmit(e) {
    e.preventDefault()
    handleDone(values)
  }

  return (
    <>
      <Countdown count={2} value={progress} />
      <form onSubmit={handleSubmit}>
        <div className={classes.section}>
          <QuestionLabel>How many cameras?</QuestionLabel>

          <div className={classes.buttonGrid}>
            {Object.entries(numberOfCams).map(([key, value]) => (
              <QuestionnaireButton
                className={classes.gridButtons}
                key={key}
                type="button"
                variant={values.numberOfCams === key && "filled"}
                name={key}
                onClick={e => {
                  setFieldValue("numberOfCams", e.currentTarget.name)
                }}
              >
                {value.label}
                <span className="block text-sm">+${value.price}</span>
              </QuestionnaireButton>
            ))}
          </div>
        </div>

        <div
          className={classes.backBtn}
          onClick={e => handleBack(values)}
          style={{ outline: "none" }}
        >
          BACK
        </div>

        {progress === 100 && (
          <button
            type="submit"
            className={classes.nextBtn}
            style={{ outline: "none" }}
          >
            NEXT
          </button>
        )}

        <div className={classes.price}>
          <PriceTag price={price({ total, values })} />
        </div>
      </form>
    </>
  )
}

export default Cameras
