import React from "react"
import clsx from "clsx"

export default function QuestionHeading({ className, ...rest }) {
  return (
    <h2
      style={{ fontFamily: "Spartan" }}
      className={clsx(
        className,
        "font-primary",
        "md:text-2xl",
        "font-bold",
        "text-primary",
        "my-0 md:my-8",
        "md:mx-16"
      )}
      {...rest}
    />
  )
}
