import React, { useEffect, useState } from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import classes from "./Countdown.module.css"

const useStyles = makeStyles({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    zIndex: "20",
  },
  colorPrimary: {
    color: "#BEFFD0",
  },
})

function Countdown({ count, ...rest }) {
  const width = typeof window !== "undefined" && window.innerWidth
  const [size, setSize] = useState("80vh")

  useEffect(() => {
    setSize(width < 768 ? "55vh" : "80vh")
  }, [width])

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="static"
        thickness={1}
        size={size}
        className={classes.progress}
        classes={useStyles()}
        {...rest}
      />

      <div className={classes.countContainer}>
        <div className={clsx(classes.countCircle, "text-green font-primary")}>
          {count}
        </div>
      </div>
    </div>
  )
}

export default Countdown
