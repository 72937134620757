import React, { useEffect, useState } from "react"
import CountUp from "react-countup"
import Tag from "../../assets/svg/price_tag.png.svg"
import "./PriceTag.css"

function PriceTag({ price }) {
  const [state, setstate] = useState(price)
  useEffect(() => {
    if (!price) {
      setstate(prev => prev)
    }
  })
  return (
    <div className="price-tag-container">
      <Tag />
      <div className="price-tag-value">
        <CountUp preserveValue={price} start={state} end={price} duration={1} />{" "}
        $
      </div>
    </div>
  )
}

export default PriceTag
