import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import clsx from "clsx"

import QuestionnaireButton from "../../atoms/QuestionnaireButton"
import classes from "./QS1.module.css"
import QuestionHeading from "../QuestionHeading"
import QuestionLabel from "../QuestionLabel"
import Countdown from "../Countdown"
import PriceTag from "../PriceTag/PriceTag"
import { useMediaQuery } from "react-responsive"

export const price = ({ total, values }) => {
  let nextTotal = total

  return nextTotal
}

export const stepPrice = ({ total, values }) => {
  let previousTotal = total

  return previousTotal
}

export const getPreviousStepId = ({ values }) => {
  return "includePhotos"
}

export const getNextStepId = ({ values }) => {
  const { category } = values

  if (category !== "Events Wedding" && category !== "MultiCam")
    return "moreThan1FinalVideo"
  else return "finalEditedVideo"
}

const validate = values => {
  const errors = {}

  return errors
}

function MusicPreference(props) {
  const {
    handleDone,
    total,
    handleBack,
    data,
    setState,
    setMakeRotate,
    makeRotate,
  } = props
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })

  const initialValues = data
    ? data
    : {
        hasMusicPreference: null,
        musicPreference: null,
      }

  const formikConfig = {
    validate,
    initialValues,
    isInitialValid: false,
  }

  const { values, handleChange, setFieldValue, isValid } = useFormik(
    formikConfig
  )

  const [progress, setProgress] = useState(25)

  useEffect(
    function () {
      let newProgress = 20

      if (values.hasMusicPreference) {
        newProgress += 40
      }

      if (values.musicPreference) {
        newProgress += 40
      }

      if (progress !== newProgress) {
        setProgress(newProgress)
      }
    },
    [values]
  )

  if (typeof window !== "undefined")
    window.addEventListener(
      "resize",
      function () {
        window.scrollTo(0, document.body.scrollHeight)
        document.body.scrollTop = 0
      },
      { passive: true }
    )

  function handleSubmit(e) {
    e.preventDefault()
    handleDone(values)
  }

  return (
    <>
      <Countdown count={2} value={progress} />
      <form onSubmit={handleSubmit}>
        <div className={classes.section}>
          {((!values.hasMusicPreference && isMobile) || !isMobile) && (
            <div>
              <QuestionHeading>
                MUSIC IS AN ESSENTIAL PART OF THE PROJECT, IT ADDS TO THE
                STORYTELLING.
              </QuestionHeading>

              <QuestionLabel>
                Do you have any preferences regarding the music?
              </QuestionLabel>

              <div className="flex justify-center">
                <QuestionnaireButton
                  type="button"
                  className={clsx(classes.yesNoBtns, "mr-4 md:mr-16")}
                  onClick={() => {
                    if (!isMobile) {
                      setMakeRotate(!makeRotate)
                    }
                    if (isMobile) setState("mobile3")
                    setFieldValue("hasMusicPreference", "yes")
                  }}
                  variant={values.hasMusicPreference === "yes" && "filled"}
                >
                  Yes
                </QuestionnaireButton>

                <QuestionnaireButton
                  className={classes.yesNoBtns}
                  type="button"
                  onClick={() => {
                    if (!isMobile) {
                      setMakeRotate(!makeRotate)
                    }
                    if (isMobile) setState("mobile2")
                    setFieldValue("hasMusicPreference", "no")
                  }}
                  variant={values.hasMusicPreference === "no" && "filled"}
                >
                  No, please choose for me
                </QuestionnaireButton>
              </div>
            </div>
          )}

          {values.hasMusicPreference === "no" && (
            <div>
              <QuestionLabel className="mt-8">
                What kind of music do you like?
              </QuestionLabel>

              <div className={classes.buttonGrid}>
                {[
                  "Rock",
                  "Electro",
                  "Hip-Hop",
                  "Classic",
                  "Jazz",
                  "Pop",
                  "Indie",
                  "Blues",
                  "Other",
                ].map(genre => (
                  <>
                    {genre !== "Other" ? (
                      <QuestionnaireButton
                        className={classes.gridButtons}
                        key={genre}
                        type="button"
                        variant={values.musicPreference === genre && "filled"}
                        name={genre}
                        onClick={e => {
                          setFieldValue("musicPreference", genre)
                        }}
                      >
                        {genre}
                      </QuestionnaireButton>
                    ) : (
                      <QuestionnaireButton
                        className={classes.yesNoBtns}
                        style={{
                          gridColumn: "1/3",
                        }}
                        key={genre}
                        type="button"
                        // variant={values.musicPreference === genre && "filled"}
                        name={genre}
                        onClick={e => setFieldValue("musicPreference", genre)}
                      >
                        Other
                        {!isMobile && (
                          <input
                            onChange={e =>
                              setFieldValue("musicPreference", e.target.value)
                            }
                            style={{
                              marginLeft: "2rem",
                              width: "230px",
                              paddingLeft: "0.5rem",
                              paddingRight: "0.5rem",
                              color: "black",
                            }}
                            type="text"
                            placeholder="Please specify here"
                          />
                        )}
                      </QuestionnaireButton>
                    )}
                  </>
                ))}
              </div>
            </div>
          )}

          {values.hasMusicPreference === "yes" && (
            <>
              <QuestionLabel className="mt-8">
                Please specify the artist and the name of the song you want to
                add
              </QuestionLabel>

              <input
                placeholder="Type in here"
                className="p-4 w-full border border-solid border-black"
                value={values.musicPreference}
                name="musicPreference"
                onChange={handleChange}
              />
            </>
          )}
        </div>

        <div
          className={classes.backBtn}
          onClick={e => handleBack(values)}
          style={{ outline: "none" }}
        >
          BACK
        </div>

        {progress === 100 && (
          <button
            type="submit"
            className={classes.nextBtn}
            style={{ outline: "none" }}
          >
            NEXT
          </button>
        )}

        <div className={classes.price}>
          <PriceTag price={price({ total, values })} />
        </div>
      </form>
    </>
  )
}

export default MusicPreference
