import React, { useState, useEffect, useRef } from "react"
import { useFormik } from "formik"

import classes from "./QS1.module.css"
import QuestionHeading from "../QuestionHeading"
import QuestionLabel from "../QuestionLabel"
import Countdown from "../Countdown"

const categories = {
  wedding: "Events Wedding",
  holiday: "Holiday Family",
  corporate: "Corporate Commercial",
  food: "Food Services",
  realestate: "Real estates Resorts",
  sports: "Sports Adrenaline",
  timelapse: "Time lapse",
  trim: "Trimming Scrubbing",
  multicam: "MultiCam",
  gopro: "Drone GoPro",
  youtube: "Youtube Instagram",
  other: "Other",
}

export const price = () => 0
export const stepPrice = () => 0

export const getPreviousStepId = ({ values }) => {
  return "quickSteps"
}

export const getNextStepId = ({ values }) => {
  const { category } = values

  const nextStepIdByCategory = {
    [categories.wedding]: "lengthOfRawFootage",
    [categories.holiday]: "lengthOfRawFootage",
    [categories.corporate]: "lengthOfRawFootage",
    [categories.food]: "lengthOfRawFootage",
    [categories.realestate]: "lengthOfRawFootage",
    [categories.sports]: "lengthOfRawFootage",
    [categories.gopro]: "lengthOfRawFootage",
    [categories.social]: "lengthOfRawFootage",
    [categories.youtube]: "lengthOfRawFootage",
    [categories.timelapse]: "timeLapse",
    [categories.trim]: "trimming",
    [categories.multicam]: "lengthOfRawFootage",
    [categories.other]: "lengthOfRawFootage",
  }

  return nextStepIdByCategory[category]
}

const validate = ({ description, descriptionType }) => {
  const errors = {}

  if (!descriptionType) {
    errors.descriptionType = true
  }

  if (!description && descriptionType === "custom") {
    errors.description = true
  }

  return errors
}

const ImportantScenes = props => {
  const { handleDone, data, setState, handleBack } = props
  const [progress, setProgress] = useState(25)

  const initialValues = data
    ? data
    : {
        descriptionType: "",
        description: "",
      }

  const formikConfig = {
    validate,
    initialValues,
    isInitialValid: false,
  }

  const { values, handleChange, setFieldValue, isValid } = useFormik(
    formikConfig
  )

  useEffect(
    function () {
      let newProgress = 25

      newProgress += 25

      if (values.description) {
        newProgress += 50
      }

      if (values.descriptionType === "Just do the best with my footage") {
        newProgress += 50
      }

      if (progress !== newProgress) {
        setProgress(newProgress)
      }
    },
    [values]
  )

  function handleSubmit(e) {
    e.preventDefault()
    handleDone(values)
  }

  return (
    <>
      <Countdown count={3} value={progress} />

      <form onSubmit={handleSubmit}>
        <div className={classes.section}>
          <QuestionHeading>
            STORYTELLING IS AN ARTFORM, WHICH COMES INTO BEING THROUGH THE FINE
            DETAILS EMBEDDED IN IT.
          </QuestionHeading>

          <QuestionLabel>
            Which scenes are important to you? Please take a minute.
          </QuestionLabel>
          <QuestionLabel>Will you?</QuestionLabel>

          <label htmlFor="custom" className="flex md:ml-8 mb-4">
            <input
              className="mr-4"
              type="radio"
              id="custom"
              value="custom"
              name="descriptionType"
              onChange={handleChange}
              checked={values.descriptionType === "custom"}
            />

            <textarea
              name="description"
              value={values.description}
              onChange={handleChange}
              onFocus={e => setFieldValue("descriptionType", "custom")}
              rows="4"
              className="flex-grow border border-black resize-none font-primary font-semibold p-2"
              placeholder="Describe your project here (recommended)"
            />
          </label>

          <label htmlFor="default" className="md:ml-8">
            <input
              type="radio"
              id="default"
              value="Just do the best with my footage"
              name="descriptionType"
              onClick={e => setFieldValue("description", "")}
              onChange={handleChange}
              checked={
                values.descriptionType === "Just do the best with my footage"
              }
            />

            <span className="ml-4 font-primary font-semibold">
              Just do the best with my footage
            </span>
          </label>
        </div>

        <div
          className={classes.backBtn}
          onClick={e => handleBack(values)}
          style={{ outline: "none" }}
        >
          BACK
        </div>

        <div className={classes.bottom}>
          {progress === 100 && (
            <button
              type="submit"
              className={classes.nextBtn}
              style={{ outline: "none" }}
            >
              NEXT
            </button>
          )}
        </div>
      </form>
    </>
  )
}

export default ImportantScenes
