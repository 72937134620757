import React from "react"
import clsx from "clsx"

export default function QuestionLabel({ className, ...rest }) {
  return (
    <h2
      style={{ fontFamily: "Libre Baskerville" }}
      className={clsx(
        className,
        "md:my-2",
        "font-secondary",
        "md:text-2xl",
        "font-bold",
        "text-secondary"
      )}
      {...rest}
    />
  )
}
