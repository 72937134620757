import React, { useEffect, useState } from "react"
import { motion } from "framer-motion"
import ShutterBlade from "../assets/shutter-blade.svg"
import classes from "./ShutterOverlay.module.css"

const numberOfBlades = 12

const blades = []

for (let i = 0; i < numberOfBlades; i++) {
  blades.push(i)
}

const statesToRotation = {
  mobile1: 8,
  mobile2: 5.5,
  mobile3: 5,
  desktop1: 15,
  desktop2: 13,
  desktop3: 11,
}

function ShutterOverlay({ children, state = "mobile1", leaving, makeRotate }) {
  const [open, setOpen] = useState(false)
  const x = makeRotate ? -15 : 0
  const rotate = (!leaving && statesToRotation[state]) || 0
  const [firstRender, setFirstRender] = useState(false)

  useEffect(() => {
    setFirstRender(true)
  }, [])

  function renderBlade(index) {
    const rotation = (360 / numberOfBlades) * index

    return (
      <>
        <motion.div key={index} className={classes.renderBlade}>
          <motion.div
            className={classes.rotateClass}
            style={{
              transformOrigin: "0% 0%",
            }}
            transition={{ duration: 0.3 }}
            initial={{ rotate: rotation }}
            animate={{
              rotate: rotation + x,
              willChange: rotation,
            }}
          >
            <motion.div
              className={classes.rotateClass}
              style={{
                x: 0,
                y: -810,
                transformOrigin: state.includes("mobile")
                  ? "right 51%"
                  : state === "desktop1"
                  ? `right 58%`
                  : state === "desktop2"
                  ? "right 57%"
                  : "right 56%",
                transform: rotate,
                willChange: "transform",
              }}
              transition={{ duration: 0.3 }}
              animate={{
                rotate: rotate,
                willChange: rotate,
              }}
            >
              <ShutterBlade width="2600px" height="2600px" />
            </motion.div>
          </motion.div>
        </motion.div>
      </>
    )
  }

  return (
    <>
      <motion.div className={classes.root}>
        {blades.map(renderBlade)}
      </motion.div>
      {children}
    </>
  )
}

export default ShutterOverlay
