import React, { useEffect, useState } from "react"
import Dropdown from "./Dropdown"
import LogoImage from "../gatsbyImages/LogoImage"

import Follow3QuickSteps, {
  price as Follow3QuickStepsPrice,
  stepPrice as Follow3QuickStepsStepPrice,
  getNextStepId as Follow3QuickStepsGetNextStepId,
} from "./questionnaire-steps/Follow3QuickSteps"

import ImportantScenes, {
  price as ImportantScenesPrice,
  stepPrice as ImportantScenesStepPrice,
  getNextStepId as ImportantScenesGetNextStepId,
  getPreviousStepId as ImportantScenesGetPreviousStepId,
} from "./questionnaire-steps/ImportantScenes"

import LengthOfRawFootage, {
  price as LengthOfRawFootagePrice,
  stepPrice as LengthOfRawFootageStepPrice,
  getNextStepId as LengthOfRawFootageGetNextStepId,
  getPreviousStepId as LengthOfRawFootageGetPreviousStepId,
} from "./questionnaire-steps/LengthOfRawFootage"

import IncludePhotos, {
  price as IncludePhotosPrice,
  stepPrice as IncludePhotosStepPrice,
  getNextStepId as IncludePhotosGetNextStepId,
  getPreviousStepId as IncludePhotosGetPreviousStepId,
} from "./questionnaire-steps/IncludePhotos"

import MusicPreference, {
  price as MusicPreferencePrice,
  stepPrice as MusicPreferenceStepPrice,
  getNextStepId as MusicPreferenceGetNextStepId,
  getPreviousStepId as MusicPreferenceGetPreviousStepId,
} from "./questionnaire-steps/MusicPreference"

import Cameras, {
  price as CamerasPrice,
  stepPrice as CamerasStepPrice,
  getNextStepId as CamerasGetNextStepId,
  getPreviousStepId as CamerasGetPreviousStepId,
} from "./questionnaire-steps/Cameras"

import FinalEditedVideo, {
  price as FinalEditedVideoPrice,
  stepPrice as FinalEditedVideoStepPrice,
  getNextStepId as FinalEditedVideoGetNextStepId,
  getPreviousStepId as FinalEditedVideoGetPreviousStepId,
} from "./questionnaire-steps/FinalEditedVideo"

import MoreThan1FinalVideo, {
  price as MoreThan1FinalVideoPrice,
  stepPrice as MoreThan1FinalVideoStepPrice,
  getNextStepId as MoreThan1FinalVideoGetNextStepId,
  getPreviousStepId as MoreThan1FinalVideoGetPreviousStepId,
} from "./questionnaire-steps/MoreThan1FinalVideo"

import DeliveryTime, {
  price as DeliveryTimePrice,
  stepPrice as DeliveryTimeStepPrice,
  getNextStepId as DeliveryTimeGetNextStepId,
  getPreviousStepId as DeliveryTimeGetPreviousStepId,
} from "./questionnaire-steps/DeliveryTime"

import Email, {
  price as EmailPrice,
  stepPrice as EmailStepPrice,
  getNextStepId as EmailGetNextStepId,
  getPreviousStepId as EmailGetPreviousStepId,
} from "./questionnaire-steps/Email"

import TimeLapse, {
  price as TimeLapsePrice,
  stepPrice as TimeLapseStepPrice,
  getNextStepId as TimeLapseGetNextStepId,
  getPreviousStepId as TimeLapseGetPreviousStepId,
} from "./questionnaire-steps/TimeLapse"

import Trimming, {
  price as TrimmingPrice,
  stepPrice as TrimmingStepPrice,
  getNextStepId as TrimmingGetNextStepId,
  getPreviousStepId as TrimmingGetPreviousStepId,
} from "./questionnaire-steps/Trimming"

import Discount, {
  price as DiscountPrice,
  getNextStepId as DiscountGetNextStepId,
} from "./questionnaire-steps/Discount"

import ShutterOverlay from "../components/ShutterOverlay"
import classes from "../pages/questionnaire.module.css"
import clsx from "clsx"
import { navigate } from "gatsby-link"
import { loadStripe } from "@stripe/stripe-js"

const stepComponentMap = {
  quickSteps: Follow3QuickSteps,
  importantScenes: ImportantScenes,
  lengthOfRawFootage: LengthOfRawFootage,
  includePhotos: IncludePhotos,
  musicPreference: MusicPreference,
  cameras: Cameras,
  deliveryTime: DeliveryTime,
  email: Email,
  timeLapse: TimeLapse,
  trimming: Trimming,
  finalEditedVideo: FinalEditedVideo,
  moreThan1FinalVideo: MoreThan1FinalVideo,

  discount: Discount,
}

const priceMap = {
  quickSteps: Follow3QuickStepsPrice,
  importantScenes: ImportantScenesPrice,
  lengthOfRawFootage: LengthOfRawFootagePrice,
  includePhotos: IncludePhotosPrice,
  musicPreference: MusicPreferencePrice,
  cameras: CamerasPrice,
  deliveryTime: DeliveryTimePrice,
  email: EmailPrice,
  timeLapse: TimeLapsePrice,
  trimming: TrimmingPrice,
  finalEditedVideo: FinalEditedVideoPrice,
  moreThan1FinalVideo: MoreThan1FinalVideoPrice,

  discount: DiscountPrice,
}

const stepPriceMap = {
  quickSteps: Follow3QuickStepsStepPrice,
  importantScenes: ImportantScenesStepPrice,
  lengthOfRawFootage: LengthOfRawFootageStepPrice,
  includePhotos: IncludePhotosStepPrice,
  musicPreference: MusicPreferenceStepPrice,
  cameras: CamerasStepPrice,
  deliveryTime: DeliveryTimeStepPrice,
  email: EmailStepPrice,
  timeLapse: TimeLapseStepPrice,
  trimming: TrimmingStepPrice,
  finalEditedVideo: FinalEditedVideoStepPrice,
  moreThan1FinalVideo: MoreThan1FinalVideoStepPrice,
}

const getNextStepIdMap = {
  quickSteps: Follow3QuickStepsGetNextStepId,
  importantScenes: ImportantScenesGetNextStepId,
  lengthOfRawFootage: LengthOfRawFootageGetNextStepId,
  includePhotos: IncludePhotosGetNextStepId,
  musicPreference: MusicPreferenceGetNextStepId,
  cameras: CamerasGetNextStepId,
  deliveryTime: DeliveryTimeGetNextStepId,
  email: EmailGetNextStepId,
  timeLapse: TimeLapseGetNextStepId,
  trimming: TrimmingGetNextStepId,
  finalEditedVideo: FinalEditedVideoGetNextStepId,
  moreThan1FinalVideo: MoreThan1FinalVideoGetNextStepId,

  discount: DiscountGetNextStepId,
}

const getPreviousStepIdMap = {
  importantScenes: ImportantScenesGetPreviousStepId,
  lengthOfRawFootage: LengthOfRawFootageGetPreviousStepId,
  includePhotos: IncludePhotosGetPreviousStepId,
  musicPreference: MusicPreferenceGetPreviousStepId,
  cameras: CamerasGetPreviousStepId,
  deliveryTime: DeliveryTimeGetPreviousStepId,
  email: EmailGetPreviousStepId,
  timeLapse: TimeLapseGetPreviousStepId,
  trimming: TrimmingGetPreviousStepId,
  finalEditedVideo: FinalEditedVideoGetPreviousStepId,
  moreThan1FinalVideo: MoreThan1FinalVideoGetPreviousStepId,
}

function handleCheckoutClick(price) {
  loadStripe(
    "pk_live_51HlFaPKWbA8KCvgiBLJCt0AW4lCpZdzps0ZYgTeKEXnAK0jH2sGwCJz3KxGiKe3ZJrg51XdAPelVm1dEibS1ymhc00fchVetUa"
  ).then(stripe => {
    fetch(`https://madjon-website.netlify.app/.netlify/functions/payment`, {
      method: "POST",
      body: JSON.stringify({ price: price }),
    })
      .then(r => r.json())
      .then(session => stripe.redirectToCheckout({ sessionId: session.id }))
      .then(result => {
        if (result.error) {
          alert(result.error.message)
        }
      })
      .catch(error => {
        console.error("Error:", error)
      })
  })
}

const Questionnaire = () => {
  const [isDropdownOpen, setisDropdownOpen] = useState(false)
  const [price, setPrice] = useState(0)
  const [data, setData] = useState({})
  const [leaving, setLeaving] = useState(false)
  const [activeQuestionnaireStep, setActiveQuestionnaireStep] = useState(
    "quickSteps"
  )
  const [state, setState] = useState("desktop1")
  const isMobile = typeof window !== "undefined" && window.screen.width
  const [makeRotate, setMakeRotate] = useState(false)

  useEffect(() => {
    if (isMobile < 768) {
      if (activeQuestionnaireStep === "quickSteps") {
        setState("mobile2")
      } else {
        setState("mobile3")
      }
    } else {
      setState("desktop1")
    }
  }, [activeQuestionnaireStep])

  function handleNextStep(values, nextStepId) {
    setPrice(priceMap[activeQuestionnaireStep]({ total: price, values }))
    setLeaving(true)

    setTimeout(() => {
      setActiveQuestionnaireStep(nextStepId)
      setLeaving(false)
    }, 1000)
  }

  function handleDone(values) {
    const getNextStepId = getNextStepIdMap[activeQuestionnaireStep]
    const nextStepId = getNextStepId({ values })
    setData({ ...data, ...values })

    if (nextStepId === "done") {
      goToPayment(values)
    } else {
      handleNextStep(values, nextStepId)
      navigate("/questionnaire")
    }
  }

  function handlePreviousStep(values, previousStepId) {
    setPrice(stepPriceMap[previousStepId]({ total: price, values: data }))
    setLeaving(true)

    setTimeout(() => {
      setActiveQuestionnaireStep(previousStepId)
      setLeaving(false)
    }, 1000)
  }

  function handleBack(values) {
    const getPreviousStepId = getPreviousStepIdMap[activeQuestionnaireStep]
    const previousStepId = getPreviousStepId({ values })
    handlePreviousStep(values, previousStepId)
    navigate("/questionnaire")
  }

  function handleDiscount(values) {
    setData({ ...data, ...values })
    handleNextStep(values, "discount")
    navigate("/questionnaire")
  }

  function goToPayment(values) {
    if (Object.keys(data).length !== 0) {
      localStorage.setItem(
        "data",
        JSON.stringify({
          ...data,
          ...values,
          totalAmount: priceMap[activeQuestionnaireStep]({
            total: price,
            values,
          }),
          amountPaid: parseFloat(
            (
              priceMap[activeQuestionnaireStep]({
                total: price,
                values,
              }) * 0.2
            ).toFixed(2)
          ),
        })
      )
    }
    handleCheckoutClick(
      priceMap[activeQuestionnaireStep]({
        total: price,
        values,
      })
    )
  }

  const ActiveStepComponent = stepComponentMap[activeQuestionnaireStep]

  return (
    //state -> determines how much the shutter should open
    //can be set to different sizes e.g:
    //state -> mobile1 (largest mobile frame)
    //state -> mobile2 (middle mobile frame)
    //state -> mobile3 (smallest mobile frame)
    //state -> desktop1 (largest desktop frame)
    //state -> desktop2 (middle desktop frame)
    //state -> desktop3 (smallest desktop frame)

    <div className={classes.mobileFixed}>
      <ShutterOverlay state={state} leaving={leaving} makeRotate={makeRotate} />

      <div className={clsx(classes.root, { [classes.leaving]: leaving })}>
        <div className={classes.container}>
          {!isDropdownOpen && (
            <div
              className={classes.logo}
              onClick={e => setisDropdownOpen(!isDropdownOpen)}
            >
              {isMobile > 768 && <LogoImage />}
            </div>
          )}

          {isDropdownOpen && isMobile > 768 && (
            <Dropdown questionnaire={true} />
          )}
          <div onClick={e => setisDropdownOpen(false)}>
            <ActiveStepComponent
              handleDone={handleDone}
              handleBack={handleBack}
              total={price}
              data={data}
              handleDiscount={handleDiscount}
              setState={setState}
              setMakeRotate={setMakeRotate}
              makeRotate={makeRotate}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Questionnaire
