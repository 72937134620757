import React, { useState, useEffect } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useFormik } from "formik"

import QuestionnaireButton from "../../atoms/QuestionnaireButton"
import classes from "./QS1.module.css"
import QuestionLabel from "../QuestionLabel"
import Countdown from "../Countdown"
import PriceTag from "../PriceTag/PriceTag"
import { useMediaQuery } from "react-responsive"

const footageLengthCategories = {
  upTo15: "up to 15",
  from15to30: "15-30",
  from31to61: "31-60",
  from61to90: "61-90",
  from91to120: "91-120",
  from121to150: "121-150",
  from151to180: "151-180",
  moreThan180: "More than 180",
}

const moreThan180FootageLengthCategories = {
  from181to210: "181-210",
  from211to240: "211-240",
  from241to270: "241-270",
  from271to300: "271-300",
}

const priceByFootageLengthCategory = {
  upTo15: 89,
  from15to30: 119,
  from31to61: 159,
  from61to90: 219,
  from91to120: 279,
  from121to150: 339,
  from151to180: 399,
}

const moreThan180FootageLengthCategoryPrice = {
  from181to210: 459,
  from211to240: 499,
  from241to270: 559,
  from271to300: 619,
}

export const price = ({ total, values }) => {
  let nextTotal = total

  if (
    values.footageLengthCategory === "moreThan180" &&
    moreThan180FootageLengthCategoryPrice[
      values.moreThan180FootageLengthCategory
    ]
  ) {
    nextTotal +=
      moreThan180FootageLengthCategoryPrice[
        values.moreThan180FootageLengthCategory
      ]
  } else if (
    values.footageLengthCategory &&
    priceByFootageLengthCategory[values.footageLengthCategory]
  ) {
    nextTotal += priceByFootageLengthCategory[values.footageLengthCategory]
  }

  return nextTotal
}

export const stepPrice = ({ total, values }) => {
  let previousTotal = total

  if (
    values.footageLengthCategory === "moreThan180" &&
    moreThan180FootageLengthCategoryPrice[
      values.moreThan180FootageLengthCategory
    ]
  ) {
    previousTotal -=
      moreThan180FootageLengthCategoryPrice[
        values.moreThan180FootageLengthCategory
      ]
  } else if (
    values.footageLengthCategory &&
    priceByFootageLengthCategory[values.footageLengthCategory]
  ) {
    previousTotal -= priceByFootageLengthCategory[values.footageLengthCategory]
  }

  return previousTotal
}

export const getPreviousStepId = ({ values }) => {
  return "importantScenes"
}

export const getNextStepId = ({ values }) => {
  const { category } = values

  if (category === "MultiCam") return "cameras"
  else return "includePhotos"
}

const validate = values => {
  const errors = {}

  return errors
}

function LengthOfRawFootage(props) {
  const {
    handleDone,
    total,
    handleBack,
    data,
    setState,
    setMakeRotate,
    makeRotate,
  } = props
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })

  const initialValues = data
    ? data
    : {
        footageLengthCategory: "",
        moreThan180FootageLengthCategory: "",
      }

  const formikConfig = {
    validate,
    initialValues,
    isInitialValid: false,
  }

  const { values, handleChange, setFieldValue, isValid } = useFormik(
    formikConfig
  )

  function handleButtonSelect(e) {
    setFieldValue("footageLengthCategory", e.currentTarget.name)

    if (!isMobile) {
      if (e.currentTarget.name === "moreThan180") setMakeRotate(!makeRotate)
    }

    if (e.currentTarget.name === "moreThan180" && isMobile) {
      setState("mobile2")
    }

    localStorage.setItem("footageLengthCategory", e.currentTarget.name)
  }

  function moreThan180handleButtonSelect(e) {
    setFieldValue("moreThan180FootageLengthCategory", e.currentTarget.name)

    localStorage.setItem("footageLengthCategory", e.currentTarget.name)
  }

  const [progress, setProgress] = useState(25)

  useEffect(
    function () {
      let newProgress = 20

      if (values.footageLengthCategory) {
        newProgress += 80
      }

      if (progress !== newProgress) {
        setProgress(newProgress)
      }
    },
    [values]
  )

  function handleSubmit(e) {
    e.preventDefault()
    handleDone(values)
  }

  if (typeof window !== "undefined")
    window.addEventListener(
      "resize",
      function () {
        window.scrollTo(0, document.body.scrollHeight)
        document.body.scrollTop = 0
      },
      { passive: true }
    )

  return (
    <>
      <Countdown count={2} value={progress} />
      <form onSubmit={handleSubmit}>
        <div className={classes.section}>
          <QuestionLabel>
            What is the length of your raw footage (minutes)?
          </QuestionLabel>

          <div className={classes.buttonGrid}>
            {Object.entries(footageLengthCategories).map(([key, value]) => (
              <QuestionnaireButton
                className={classes.gridButtons}
                key={key}
                type="button"
                variant={values.footageLengthCategory === key && "filled"}
                name={key}
                onClick={e => {
                  handleButtonSelect(e)
                }}
              >
                {value}
                {priceByFootageLengthCategory[key] && (
                  <span className="block text-sm">
                    +${priceByFootageLengthCategory[key]}
                  </span>
                )}
              </QuestionnaireButton>
            ))}
          </div>

          {values.footageLengthCategory &&
            values.footageLengthCategory === "moreThan180" && (
              <div className={classes.buttonGrid180}>
                {Object.entries(moreThan180FootageLengthCategories).map(
                  ([key, value]) => (
                    <QuestionnaireButton
                      className={classes.gridButtons}
                      key={key}
                      type="button"
                      variant={
                        values.moreThan180FootageLengthCategory === key &&
                        "filled"
                      }
                      name={key}
                      onClick={e => {
                        moreThan180handleButtonSelect(e)
                      }}
                    >
                      {value}
                      {moreThan180FootageLengthCategoryPrice[key] && (
                        <span className="block text-sm">
                          +${moreThan180FootageLengthCategoryPrice[key]}
                        </span>
                      )}
                    </QuestionnaireButton>
                  )
                )}
                <AnchorLink to="/video-editing#contactus" stripHash>
                  <QuestionnaireButton
                    type="button"
                    className={classes.moreThan300Btn}
                  >
                    More than 300
                    <span className="block text-sm">contact us</span>
                  </QuestionnaireButton>
                </AnchorLink>
              </div>
            )}
        </div>

        <div
          className={classes.backBtn}
          onClick={e => handleBack(values)}
          style={{ outline: "none" }}
        >
          BACK
        </div>

        {progress === 100 && (
          <button
            type="submit"
            className={classes.nextBtn}
            style={{ outline: "none" }}
          >
            NEXT
          </button>
        )}

        <div className={classes.price}>
          <PriceTag price={price({ total, values })} />
        </div>
      </form>
    </>
  )
}

export default LengthOfRawFootage
