import React, { useState, useEffect } from "react"
import { useFormik } from "formik"

import QuestionnaireButton from "../../atoms/QuestionnaireButton"
import classes from "./QS1.module.css"
import QuestionLabel from "../QuestionLabel"
import Countdown from "../Countdown"
import { Link } from "gatsby"
import PriceTag from "../PriceTag/PriceTag"
import { useMediaQuery } from "react-responsive"

const moreThan1Videos = {
  videos2: { label: "2 videos", price: 69 },
  videos3: { label: "3 videos", price: 99 },
}

const finalVideoLengths = [
  {
    title: "HIGHLIGHT",
    label: "Less than 5 mins",
    value: "lessThan5Mins",
    price: 30,
  },
  {
    title: "HIGHLIGHT",
    label: "5-10 mins",
    value: "from5To10mins",
    price: 50,
  },
  {
    title: "TEASER",
    label: "11-15 mins",
    value: "from11To15mins",
    price: 70,
  },
  {
    title: "FULL LENGTH",
    label: "16-20 mins",
    value: "from16To20mins",
    price: 90,
  },
]

export const price = ({ total, values }) => {
  let nextTotal = total

  if (values.moreThan1Video === "yes" && values.numberOfVideos) {
    nextTotal += moreThan1Videos[values.numberOfVideos].price
  }

  if (values.lengthOfFinalVideo) {
    nextTotal += finalVideoLengths.find(
      finalVideoLength => finalVideoLength.value === values.lengthOfFinalVideo
    ).price
  }

  return nextTotal
}

export const stepPrice = ({ total, values }) => {
  let previousTotal = total

  if (values.moreThan1Video === "yes" && values.numberOfVideos) {
    previousTotal -= moreThan1Videos[values.numberOfVideos].price
  }

  if (values.lengthOfFinalVideo) {
    previousTotal -= finalVideoLengths.find(
      finalVideoLength => finalVideoLength.value === values.lengthOfFinalVideo
    ).price
  }

  return previousTotal
}

export const getPreviousStepId = ({ values }) => {
  return "musicPreference"
}

export const getNextStepId = ({ values }) => {
  return "deliveryTime"
}

const validate = values => {
  const errors = {}

  return errors
}

function MoreThan1FinalVideo(props) {
  const { handleDone, handleBack, total, makeRotate, setMakeRotate } = props
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })

  const initialValues = {
    footageLengthCategory: localStorage.getItem("footageLengthCategory"),
    lengthOfFinalVideo: null,
    moreThan1Video: null,
    numberOfVideos: null,
  }

  const formikConfig = {
    validate,
    initialValues,
    isInitialValid: false,
  }

  const { values, handleChange, setFieldValue, isValid } = useFormik(
    formikConfig
  )

  const [progress, setProgress] = useState(25)

  useEffect(
    function () {
      let newProgress = 20

      if (values.lengthOfFinalVideo) {
        newProgress += 20
      }

      if (values.footageLengthCategory === "upTo15") {
        if (values.moreThan1Video === "yes") {
          newProgress += 30

          if (values.numberOfVideos) {
            newProgress += 30
          }
        }

        if (values.moreThan1Video === "no") {
          newProgress += 60
        }
      } else {
        newProgress += 60
      }

      if (progress !== newProgress) {
        setProgress(newProgress)
      }
    },
    [values]
  )

  if (typeof window !== "undefined")
    window.addEventListener(
      "resize",
      function () {
        window.scrollTo(0, document.body.scrollHeight)
        document.body.scrollTop = 0
      },
      { passive: true }
    )

  useEffect(() => {
    if (values.lengthOfFinalVideo?.length === 1 && !isMobile)
      setMakeRotate(!makeRotate)
  }, [values.lengthOfFinalVideo])

  function handleSubmit(e) {
    e.preventDefault()
    handleDone(values)
  }

  return (
    <>
      <Countdown count={1} value={progress} />
      <form onSubmit={handleSubmit}>
        {values.footageLengthCategory === "upTo15" ? (
          <div className={classes.section}>
            <QuestionLabel>
              Please specify the desired length of your final video
            </QuestionLabel>

            <input
              className="p-4 w-full border border-solid border-black"
              type="text"
              id="custom"
              placeholder="Type a value here (minutes)"
              value={values.lengthOfFinalVideo}
              name="lengthOfFinalVideo"
              onChange={e => {
                handleChange(e)
              }}
            />

            {values.lengthOfFinalVideo && (
              <>
                <QuestionLabel className="mt-16 md:mt-32">
                  Do you want more than 1 final edited video?
                </QuestionLabel>

                {values.moreThan1Video !== "yes" ? (
                  <div className={classes.buttonGrid}>
                    <QuestionnaireButton
                      type="button"
                      onClick={() => {
                        if (!isMobile) setMakeRotate(!makeRotate)
                        setFieldValue("moreThan1Video", "yes")
                      }}
                      variant={values.moreThan1Video === "yes" && "filled"}
                    >
                      Yes
                    </QuestionnaireButton>

                    <Link to="#2">
                      <QuestionnaireButton
                        type="button"
                        onClick={() => {
                          if (!isMobile) setMakeRotate(!makeRotate)
                          setFieldValue("moreThan1Video", "no")
                        }}
                        variant={values.moreThan1Video === "no" && "filled"}
                      >
                        No
                      </QuestionnaireButton>
                    </Link>
                  </div>
                ) : (
                  <div className={classes.buttonGrid}>
                    {Object.entries(moreThan1Videos).map(([key, value]) => (
                      <Link to="#2">
                        <QuestionnaireButton
                          className={classes.gridButtons}
                          key={key}
                          type="button"
                          variant={values.numberOfVideos === key && "filled"}
                          name={key}
                          onClick={e =>
                            setFieldValue(
                              "numberOfVideos",
                              e.currentTarget.name
                            )
                          }
                        >
                          {value.label}
                          <span className="block text-sm">+${value.price}</span>
                        </QuestionnaireButton>
                      </Link>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <div className={classes.section}>
            <QuestionLabel>
              What length do you choose for the final edited video?
            </QuestionLabel>
            <div className={classes.buttonGrid3}>
              {finalVideoLengths.map(lengthOfFinalVideo => (
                <QuestionnaireButton
                  className={classes.gridButtons}
                  key={lengthOfFinalVideo.value}
                  type="button"
                  variant={
                    values.lengthOfFinalVideo === lengthOfFinalVideo.value &&
                    "filled"
                  }
                  onClick={e =>
                    setFieldValue(
                      "lengthOfFinalVideo",
                      lengthOfFinalVideo.value
                    )
                  }
                >
                  <span class="block">{lengthOfFinalVideo.label}</span>
                  <span className="block text-sm">
                    +${lengthOfFinalVideo.price}
                  </span>
                </QuestionnaireButton>
              ))}
            </div>
          </div>
        )}

        <div
          className={classes.backBtn}
          onClick={e => handleBack(values)}
          style={{ outline: "none" }}
        >
          BACK
        </div>

        {progress === 100 && (
          <button
            type="submit"
            className={classes.nextBtn}
            style={{ outline: "none" }}
          >
            NEXT
          </button>
        )}

        <div className={classes.price}>
          <PriceTag price={price({ total, values })} />
        </div>
      </form>
    </>
  )
}

export default MoreThan1FinalVideo
