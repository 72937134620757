import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import classes from "./QS1.module.css"
import clsx from "clsx"
import QuestionLabel from "../QuestionLabel"
import Countdown from "../Countdown"
import PriceTag from "../PriceTag/PriceTag"

export const price = ({ values, total }) => {
  let nextPrice = total

  return nextPrice
}

export const stepPrice = ({ total, values }) => {
  let previousTotal = total

  return previousTotal
}

export const getPreviousStepId = ({ values }) => {
  return "deliveryTime"
}

export const getNextStepId = ({ values }) => {
  return "done"
}

const initialValues = {
  email: "",
}

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const validate = ({ email }) => {
  const errors = {}

  if (!email) {
    errors.name = true
  }

  return errors
}

const formikConfig = {
  validate,
  initialValues,
  isInitialValid: false,
}

function Email(props) {
  const { handleDone, handleBack, total, handleDiscount } = props

  const { values, handleChange, setFieldValue, isValid } = useFormik(
    formikConfig
  )

  const [progress, setProgress] = useState(25)

  useEffect(
    function () {
      let newProgress = 34

      if (validateEmail(values.email)) {
        newProgress += 66
      }

      if (progress !== newProgress) {
        setProgress(newProgress)
      }
    },
    [values]
  )

  if (typeof window !== "undefined")
    window.addEventListener(
      "resize",
      function () {
        window.scrollTo(0, document.body.scrollHeight)
        document.body.scrollTop = 0
      },
      { passive: true }
    )

  function handleSubmit(e) {
    e.preventDefault()
    handleDone(values)
  }

  return (
    <>
      <Countdown count={1} value={progress} />
      <form onSubmit={handleSubmit}>
        <div className={classes.section}>
          <div></div>
          <div>
            <QuestionLabel>
              Leave us your contact so we can reach you for revisions:
            </QuestionLabel>

            <input
              type="text"
              name="email"
              className={clsx(
                classes.emailInput,
                "p-4  border-b-2 focus:outline-none border-solid border-black"
              )}
              placeholder="Type your e-mail here"
              onChange={handleChange}
            />
          </div>

          <QuestionLabel className={classes.infoText}>
            You are now paying a 20% deposit to start the project. <br /> Once
            you're happy with the final edit, we will charge the rest of the
            payment.
          </QuestionLabel>
        </div>

        <div
          className={classes.backBtn}
          onClick={e => handleBack(values)}
          style={{ outline: "none" }}
        >
          BACK
        </div>

        {progress === 100 && (
          <div
            className={classes.discountBtn}
            style={{ outline: "none" }}
            onClick={e => {
              handleDiscount(values)
            }}
          >
            WANT A <br /> DISCOUNT?
          </div>
        )}

        {progress === 100 && (
          <button
            type="submit"
            className={classes.startProjectBtn}
            style={{ outline: "none" }}
          >
            START <br /> PROJECT
          </button>
        )}

        <div className={classes.priceEmail}>
          <PriceTag price={price({ total, values })} />
        </div>
      </form>
    </>
  )
}

export default Email
